import {Notification, Modal} from "./core";
import {ListingApprovalManager} from "./listing-approval-manager";

interface CouponListing {
  id: bigint,
  marketplace_id: number,
  product_id: string,
  user_id: bigint,
  code: string
}

export class CouponListingApproval {
  /**
   * Declare settings to use in the coupon approval process
   * @private listing
   */
  private readonly listing: CouponListing;

  /**
   * constructor of the class
   * @param listing
   */
  constructor(listing: CouponListing) {
    this.listing = listing;
  }

  /**
   * Initialize coupon listing approval system
   * @param $approvalForm
   * @param $cardContainer
   * @param noListingText
   * @param badgeSelector
   * @param mode
   */
  public init($approvalForm: any, $cardContainer: JQuery<HTMLElement>, noListingText: string, badgeSelector: string, mode: string): void {
    ListingApprovalManager.initListingApproval($approvalForm, $cardContainer, noListingText, badgeSelector, mode, this);
  }

  /**
   * Approve or disapprove coupons, processing one or multiple items
   *
   * @param $form
   * @param badgeSelector
   * @param noListingText
   * @param $cardContainer
   * @param mode
   * @private
   */
  private processApprovalForm(
    $form: JQuery<HTMLElement>,
    badgeSelector: string,
    noListingText: string,
    $cardContainer: JQuery<HTMLElement>,
    mode: string
  ): void {
    const $row = this.getRow(mode);
    const submitButton = $form.data('formValidation').getSubmitButton();
    const action = String(submitButton.attr('value'));
    const $btnProcess = $row.find('.btn-process');
    let label = submitButton.html();
    // Add action dynamically as a hidden value to send as an indicator of the operation
    $form.append('<input type="hidden" name="action" value="' + action + '">');
    submitButton.html('<i class="fal fa-spin fa-spinner"></i> Processing...');

    $.post('/ajax/coupons/coupons-approval', $form.serializeArray()).done((response) => {
      response.message && Notification.success(response.message);
      if (response.next_coupon > 0) {
        ListingApprovalManager.updateBadge(badgeSelector, response.total);
        ListingApprovalManager.updateRemaining($row, response.remaining);
        $btnProcess
          .attr('href', `html/coupon/details?id=${response.next_coupon}`)
          .html('Process')
          .next().removeAttr('disabled').removeClass('disabled');
        Modal.reload({
          href: `html/coupon/details?id=${response.next_coupon}`
        });
      } else if (response.remaining == 0) {
        if (mode == 'listing') {
          window.location.reload();
        } else {
          ListingApprovalManager.removeRow($row, $cardContainer, noListingText, badgeSelector, response);
          if (action == 'disapprove' || action == 'approve') {
            Modal.hide();
          }
        }
      }
    }).always(() => {
      if (mode != 'listing') {
        $form.data('formValidation') !== undefined && $form.data('formValidation').resetForm();
        submitButton.html(label);
        const $searchForm = $cardContainer.find('.search-form-container form');
        ListingApprovalManager.checkLastPage($searchForm.find('input[name="page"]'));
        $searchForm.trigger('submit', {loader: false, history: true});
      }
    });
    if (action === 'disapprove_all' || action === 'approve_all') {
      ListingApprovalManager.refreshRow($row, noListingText, $cardContainer, badgeSelector, mode);
      Modal.hide();
      Notification.info("Working, please be patient...");
    }
  }

  /**
   * Get the row matching the coupon we are processing.
   *
   * @param mode
   * @private
   */
  private getRow(mode: string) {
    if (mode === 'listing') {
      return $(document).find(`tr[data-id=${this.listing.id}]`);
    }
    if (mode === 'code') {
      return $(document)
        .find(`tr[data-user-id=${this.listing.user_id}][data-code=${this.listing.code}][data-marketplace-id=${this.listing.marketplace_id}]`);
    }
    return $(document)
      .find(`tr[data-user-id=${this.listing.user_id}][data-marketplace-id=${this.listing.marketplace_id}]`);
  }
}
