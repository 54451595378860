//############################ JS ############################

// jQuery
require('jquery/dist/jquery.js');
// Popper.js
require('popper.js/dist/umd/popper.js');
// Bootstrap
require('bootstrap/dist/js/bootstrap.js');
// Toastr
require('toastr/toastr.js');
// Bootstrap Notify
require('bootstrap-notify/bootstrap-notify.js');
//AOS
require('aos/dist/aos.js');
// Formvalidation
require('imports-loader?define=>false!./js/formvalidation/js/formValidation.js');
require('imports-loader?define=>false!./js/formvalidation/js/framework/bootstrap4.js');
require('imports-loader?define=>false!./js/formvalidation/js/mandatoryicon.js');
// JavaScript Cookie
require('js-cookie/src/js.cookie.js');
// CoreUI application
require('@coreui/coreui/dist/js/coreui.js');
require('@coreui/coreui/dist/js/coreui-utilities.js');
// Dropzone
require('dropzone/dist/dropzone.js');
// Date/time picker
require('imports-loader?define=>false!./js/picker/picker.js');
require('imports-loader?define=>false!./js/picker/picker-date.js');
require('imports-loader?define=>false!./js/picker/picker-time.js');
// Codemirror
require('codemirror/lib/codemirror.js');
require('codemirror/mode/htmlembedded/htmlembedded.js');
// Summernote
require('summernote/dist/summernote-bs4.js');
// Slick carousel
require('slick-carousel/slick/slick.js');
// Select2
require('select2/dist/js/select2.full.js');
// Flipclock
require('flipclock/compiled/flipclock.js');
// Infinite scroll
require('infinite-scroll/dist/infinite-scroll.pkgd.js');
// Smooth scroll
require('smooth-scroll/dist/smooth-scroll.js');
// Clipboard
require('clipboard/dist/clipboard.min.js');
// Inputmask
require('inputmask/dist/jquery.inputmask.js');
// Lozad
require('lozad/dist/lozad.js');
// Bootstrap Multiselect
require('bootstrap-multiselect/dist/js/bootstrap-multiselect.js');
// Typeahead.js
require('typeahead.js/dist/typeahead.bundle.js');
require('typeahead.js/dist/bloodhound.js');
// Sortable
import "expose-loader?exposes[]=Sortable!sortablejs/Sortable.js";
// Project
require('./js/project/index.ts');
require('./js/init.js');

//############################ CSS ############################

// Font Awesome
require('@fortawesome/fontawesome-pro/css/all.css');
// Animate
require('animate.css/animate.css');
//AOS
require('aos/dist/aos.css');
// Formvalidation
require('./js/formvalidation/css/formValidation.css');
// Dropzone
require('dropzone/dist/dropzone.css');
// Slick carousel
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');
// Codemirror
require('codemirror/lib/codemirror.css');
// Summernote
require('summernote/dist/summernote-bs4.css');
// Select2
require('select2/src/scss/core.scss');
// Flipclock
require('flipclock/compiled/flipclock.css');
// Bootstrap Multiselect
require('bootstrap-multiselect/dist/css/bootstrap-multiselect.css');
// Project
require('./scss/project.scss');