import {Modal} from "./core";
import {Favorite} from "./favorite";
import {WebPush} from "./core/web-push";

export class RebateListing {

    static sortPictures(id, items) {
        return $.post('/seller/campaign/ajax/sort-pictures', {
            id: id,
            items: items
        });
    }

    static removePicture(id) {
        return $.post('/seller/campaign/ajax/delete-picture', {
            id: id
        });
    }

    static rotatePicture(id) {
        return $.post('/seller/campaign/ajax/rotate-picture', {
            id: id
        });
    }

    static notify($listing) {
        const id = $listing.data('id');

        return $.post('/buyer/ajax/notification/manage', {
            id: id
        });
    }

    static notifyMethod($notification, method = 'all') {
        const id = $notification.attr('id').replace('notification-', '');

        return $.post('/buyer/ajax/notification/method', {
            id: id,
            type: method
        });
    }

    static sendPlaidLink(public_token, metadata) {
        return $.post('/seller/campaign/ajax/plaid', {
            public_token: public_token,
            account_id: metadata.account_id
        });
    }

    static init() {
        $(document).on('click', '.listing-card .listing-rebate', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $listing = $(this);
            Modal.open({
                href: '/buyer/modal/rebate/view?id=' + $listing.data('id'),
                size: 'modal-lg',
                class: 'modal-campaign'
            });
        }).on('click', '.listing-rebate .btn-notify', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $button = $(this).attr('disabled', 'disabled');
            const $listing = $button.parents('.listing-container');
            RebateListing.notify($listing).then(function (response) {
                $('[id="' + $listing.attr('id') + '"]').find('.btn-notify')
                    .html(response.html);
                if (!WebPush.isSubscribed) {
                    Modal.open({href: '/web-push/modal/manage'});
                }
            }).always(function () {
                $button.removeAttr('disabled');
            });
        }).on('click', '.listing-rebate .btn-confirm', function (event) {
            window.location.href = $(this).data('target');
        }).on('click', '.favorite', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $icon = $(this).find('.fa-heart');
            const $listing = $icon.parents('.listing-container');
            if ($icon.hasClass('fas')) {
                Favorite.remove($listing).then(function () {
                    $('[id="' + $listing.attr('id') + '"]').find('.favorite .fa-heart').removeClass('fas').addClass('fal');
                });
            } else {
                Favorite.add($listing).then(function () {
                    $('[id="' + $listing.attr('id') + '"]').find('.favorite .fa-heart').removeClass('fal').addClass('fas');
                });
            }

        }).on('click', '.share, .share .collapse, .release', function (event) {
            event.stopPropagation();
        });
    }
}