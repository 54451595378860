export class LibraryLoader {

    static async loadAmCharts() {
        const am4core = await import(/* webpackChunkName: "amcharts" */ "@amcharts/amcharts4/core");
        const am4charts = await import(/* webpackChunkName: "amcharts" */ "@amcharts/amcharts4/charts");
        const am4themes_animated: any = await import(/* webpackChunkName: "amcharts" */ "@amcharts/amcharts4/themes/animated");
        am4core.useTheme(am4themes_animated.default);
        am4core.options.commercialLicense = true;

        return {core: am4core, charts: am4charts}
    }
}