export class Favorite {

    static add($deal) {
        const data: any = {
            id: $deal.data('id'),
            type: $deal.data('type')
        };

        return $.post('/buyer/ajax/favorite/add', data, 'json').then(function () {
            $(document).trigger("favorite:added", [ $deal ]);
        });
    }

    static remove($deal) {
        const data: any = {
            id: $deal.data('id'),
            type: $deal.data('type')
        };

        return $.post('/buyer/ajax/favorite/remove', data, 'json').then(function () {
            $(document).trigger("favorite:removed", [ $deal ]);
        });
    }
}