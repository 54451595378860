
export class KeywordAutocomplete {

    static init() {
        const $search = $("#search");
        const options = {
            url: '/ajax/keywords',
            prepare: function (query, settings) {
                settings.url += '?search=' + $search.val().toString();

                return settings;
            }
        };

        const keywords = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('keyword'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: options
        });

        const inputOptions = {
            hint: true,
            highlight: true,
            minLength: 1
        };

        const datasets = {
            name: 'keywords',
            display: 'keyword',
            limit: 1000,
            source: keywords,
            templates: {
                suggestion(data) {
                    return `<div class="d-flex justify-content-between">
                                <span>${data.keyword}</span>
                            </div>`;
                }
            }
        };
        $search.typeahead(inputOptions, datasets)
            .on('typeahead:asyncrequest', function () {
                $search.parents('.twitter-typeahead')
                    .append('<div class="tt-loader"><i class="fal fa-spin fa-spinner"></i></div>');
            })
            .on('typeahead:asynccancel typeahead:asyncreceive', function () {
                $search.parents('.twitter-typeahead').find('.fa-spinner').remove();
            })
            .on('typeahead:selected', function () {
                $("#search-deals-form").trigger('submit');
            });
    }
}