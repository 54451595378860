import * as $ from "jquery";

declare var WEB_PUSH: string;

export class WebPush {

    static enabled: boolean = false;
    static isSubscribed: boolean = false;

    static init() {
        if ('serviceWorker' in navigator) {
            console.log("Registering service worker...");
            navigator.serviceWorker.register('/service-worker.js')
                .then(() => this.initializeState());
        } else {
            this.setStatus("Browser notifications aren't supported in this browser.");
        }
    }

    static setStatus(status: string) {
        $('#web-push-status').html(status);
    }

    static showSubscribeButton() {
        $('#subscribe-button').show();
        $('#unsubscribe-button').hide();
    }

    static showUnsubscribeButton() {
        $('#subscribe-button').hide();
        $('#unsubscribe-button').show();
    }

    static enable() {
        Notification.requestPermission().then((result) => {
            if (result === 'denied') {
                this.setStatus('Notifications have been disabled. Update notifications settings in your browser te receive notifications.');
            } else if (result === 'default') {
                this.setStatus("The permission request was dismissed. Update notifications settings in your browser te receive notifications.");
            } else {
                console.log("Notifications have been authorized. We'll be able to send notifications.");
                this.subscribe();
            }
        });
    }

    static initializeState() {
        console.log('Initialize service worker state...');
        // Are Notifications supported in the service worker?
        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            this.setStatus("Browser notifications aren't supported in this browser (only available for Chrome and Firefox).");
            return;
        }

        // Check the current Notification permission.
        // If its denied, it's a permanent block until the
        // user changes the permission
        if (Notification.permission === 'denied') {
            this.setStatus('Notifications have been disabled. Update notifications settings in your browser te receive notifications.');
            return;
        }

        // Check if push messaging is supported
        if (!('PushManager' in window)) {
            this.setStatus("Browser notifications aren't supported in this browser (only available for Chrome and Firefox).");
            return;
        }

        console.log("Is service worker ready?");

        // We need the service worker registration to check for a subscription
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            // Do we already have a push message subscription?
            serviceWorkerRegistration.pushManager.getSubscription()
                .then((subscription: PushSubscription) => {
                    this.isSubscribed = subscription != null;
                    if (!subscription) {
                        // We aren’t subscribed to push, so set UI
                        // to allow the user to enable push
                        this.setStatus("Browser notifications are not enabled yet.");
                        this.showSubscribeButton();
                        return;
                    }

                    // Keep your server in sync with the latest subscription
                    this.sendSubscriptionToServer(subscription).then(() => {
                        this.enabled = true;
                        this.setStatus("Browser notifications are enabled.");
                        this.showUnsubscribeButton();
                    });
                })
                .catch((error) => {
                    console.error('Error during getSubscription()', error);
                });
        });
    }

    static sendSubscriptionToServer(subscription: PushSubscription) {
        const auth = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))));
        const p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))));

        return $.ajax({
            url: '/web-push/ajax/subscribe',
            type: 'post',
            data: {endpoint: subscription.endpoint, auth: auth, p256dh: p256dh}
        });
    }

    static deleteSubscriptionFromServer(subscription: PushSubscription) {
        return $.ajax({
            url: '/web-push/ajax/unsubscribe',
            type: 'post',
            data: {endpoint: subscription.endpoint}
        });
    }

    static unsubscribe() {
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            // To unsubscribe from push messaging, you need get the
            // subcription object, which you can call unsubscribe() on.
            serviceWorkerRegistration.pushManager.getSubscription().then(
                (subscription: PushSubscription) => {
                    // Check we have a subscription to unsubscribe
                    if (!subscription) {
                        // No subscription object, so set the state
                        // to allow the user to subscribe to push
                        this.enabled = false;
                        return;
                    }

                    // We have a subscription, so call unsubscribe on it
                    subscription.unsubscribe().then(() => {
                        this.deleteSubscriptionFromServer(subscription).then(() => {
                            this.enabled = false;
                            this.setStatus("Browser notifications are disabled now.");
                            this.showSubscribeButton();
                        });
                    }).catch((e) => {
                        // We failed to unsubscribe, this can lead to
                        // an unusual state, so may be best to remove
                        // the subscription id from your data store and
                        // inform the user that you disabled push

                        console.log('Unsubscription error: ', e);
                    });
                }).catch((e) => {
                console.log('Error thrown while unsubscribing from ' + 'push messaging.', e);
            });
        });
    }

    static subscribe() {
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            console.log('Service worker ready');
            serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(WEB_PUSH)
            })
                .then((subscription: PushSubscription) => {
                    console.log('Subscribed to service worker');
                    this.sendSubscriptionToServer(subscription).then(() => {
                        this.enabled = true;
                        this.setStatus("Browser notifications are enabled.");
                        this.showUnsubscribeButton();
                    });
                })
                .catch((e) => {
                    if (Notification.permission === 'denied') {
                        // The user denied the notification permission which
                        // means we failed to subscribe and the user will need
                        // to manually change the notification permission to
                        // subscribe to push messages
                        this.setStatus('Notifications have been disabled. Update notifications settings in your browser te receive notifications.');
                    } else {
                        // A problem occurred with the subscription, this can
                        // often be down to an issue or lack of the gcm_sender_id
                        // and / or gcm_user_visible_only
                        console.log('Unable to subscribe to push.', e);
                    }
                });
        });
    }

    static urlBase64ToUint8Array(base64String: string) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
}