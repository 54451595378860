import * as $ from "jquery";

import {LibraryLoader, Notification} from "../core";

export class Admin {

    static async drawUsersChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-new-buyers')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.min = 0;

        // Create series
        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "buyers";
        series1.dataFields.dateX = "date";
        series1.name = "New buyers";
        series1.strokeWidth = 2;
        series1.tooltipText = "{buyers}";

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "verified_buyers";
        series2.dataFields.dateX = "date";
        series2.name = "New verified buyers";
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#26b99a");
        series2.tooltipText = "{verified_buyers}";

        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "sellers";
        series3.dataFields.dateX = "date";
        series3.name = "New sellers";
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#9b59b6");
        series3.tooltipText = "{sellers}";

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#26b99a");

        series3.tooltip.background.cornerRadius = 3;
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 40;
        series3.tooltip.label.textAlign = "middle";
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#9b59b6");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series1;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-users-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawRebatesChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-claims')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.min = 0;

        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "all_rebates";
        series1.dataFields.dateX = "date";
        series1.name = "All rebates";
        series1.strokeWidth = 2;
        series1.tooltipText = "{all_rebates}";
        series1.adapter.add("tooltipText", function(ev) {
            let text = "[bold]{dateX}[/]\n"
            chart.series.each(function(item) {
                if (!item.isHidden) {
                    text += "[" + item.stroke + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                }
            });
            return text;
        });

        // Create series
        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "approved_rebates";
        series2.dataFields.dateX = "date";
        series2.name = "Approved rebates";
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#cdf7f6");
        series2.tooltipText = "{approved_rebates}";
        series2.adapter.add("tooltipText", function(ev) {
            let text = "[bold]{dateX}[/]\n"
            chart.series.each(function(item) {
                if (!item.isHidden) {
                    text += "[" + item.stroke + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                }
            });
            return text;
        });

        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "paid_rebates";
        series3.dataFields.dateX = "date";
        series3.name = "Paid rebates";
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#9b59b6");
        series3.tooltipText = "{paid_rebates}";
        series3.adapter.add("tooltipText", function(ev) {
            let text = "[bold]{dateX}[/]\n"
            chart.series.each(function(item) {
                if (!item.isHidden) {
                    text += "[" + item.stroke + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                }
            });
            return text;
        });

        let series4 = chart.series.push(new am4.charts.LineSeries());
        series4.dataFields.valueY = "expired_rebates";
        series4.dataFields.dateX = "date";
        series4.name = "Expired rebates";
        series4.strokeWidth = 2;
        series4.stroke = am4.core.color("#f39c12");
        series4.tooltipText = "{expired_rebates}";
        series4.adapter.add("tooltipText", function(ev) {
            let text = "[bold]{dateX}[/]\n"
            chart.series.each(function(item) {
                if (!item.isHidden) {
                    text += "[" + item.stroke + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                }
            });
            return text;
        });

        let series5 = chart.series.push(new am4.charts.LineSeries());
        series5.dataFields.valueY = "cancelled_rebates";
        series5.dataFields.dateX = "date";
        series5.name = "Cancelled rebates";
        series5.strokeWidth = 2;
        series5.stroke = am4.core.color("#e74c3c");
        series5.tooltipText = "{cancelled_rebates}";
        series5.adapter.add("tooltipText", function(ev) {
            let text = "[bold]{dateX}[/]\n"
            chart.series.each(function(item) {
                if (!item.isHidden) {
                    text += "[" + item.stroke + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                }
            });
            return text;
        });

        let series6 = chart.series.push(new am4.charts.LineSeries());
        series6.dataFields.valueY = "denied_rebates";
        series6.dataFields.dateX = "date";
        series6.name = "Denied rebates";
        series6.strokeWidth = 2;
        series6.stroke = am4.core.color("#34495e");
        series6.tooltipText = "{denied_rebates}";
        series6.adapter.add("tooltipText", function(ev) {
            let text = "[bold]{dateX}[/]\n"
            chart.series.each(function(item) {
                if (!item.isHidden) {
                    text += "[" + item.stroke + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n";
                }
            });
            return text;
        });

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4.core.color("#000");

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#000");

        series3.tooltip.background.cornerRadius = 3;
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 40;
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#000");

        series4.tooltip.background.cornerRadius = 3;
        series4.tooltip.background.strokeOpacity = 0;
        series4.tooltip.pointerOrientation = "vertical";
        series4.tooltip.label.minWidth = 40;
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4.core.color("#000");

        series5.tooltip.background.cornerRadius = 3;
        series5.tooltip.background.strokeOpacity = 0;
        series5.tooltip.pointerOrientation = "vertical";
        series5.tooltip.label.minWidth = 40;
        series5.tooltip.getFillFromObject = false;
        series5.tooltip.background.fill = am4.core.color("#000");

        series6.tooltip.background.cornerRadius = 3;
        series6.tooltip.background.strokeOpacity = 0;
        series6.tooltip.pointerOrientation = "vertical";
        series6.tooltip.label.minWidth = 40;
        series6.tooltip.getFillFromObject = false;
        series6.tooltip.background.fill = am4.core.color("#000");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series2);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series2;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-rebates-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawRebatesNewBuyersChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-claims-new-buyers')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());

        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = "All rebates";

        let valueAxis2 = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis2.min = 0;
        valueAxis2.max = 100;
        valueAxis2.renderer.opposite = true;
        valueAxis2.title.text = "Percentage %";

        // Create series
        let series5 = chart.series.push(new am4.charts.ColumnSeries());
        series5.dataFields.valueY = "all_rebates_new_buyers_percentage";
        series5.dataFields.dateX = "date";
        series5.stacked = false;
        series5.columns.template.tooltipText = "{all_rebates_new_buyers_percentage}%";
        series5.name = "New buyers %";
        series5.tooltipText = "{name}: {all_rebates_new_buyers_percentage}%";
        series5.yAxis = valueAxis2;
        series5.fill = am4.core.color("#006064");
        series5.fillOpacity = 0.8;
        series5.strokeWidth = 0;

        let series6 = chart.series.push(new am4.charts.ColumnSeries());
        series6.columns.template.width = am4.core.percent(80);
        series6.dataFields.valueY = "all_rebates_old_buyers_percentage";
        series6.dataFields.dateX = "date";
        series6.stacked = true;
        series6.columns.template.tooltipText = "{all_rebates_old_buyers_percentage}%";
        series6.name = "Old buyers %";
        series6.tooltipText = "{name}: {all_rebates_old_buyers_percentage}%";
        series6.yAxis = valueAxis2;
        series6.fill = am4.core.color("#ccc");
        series6.fillOpacity = 0.5;
        series6.strokeWidth = 0;
        series6.hidden = true;

        let series7 = chart.series.push(new am4.charts.ColumnSeries());
        series7.dataFields.valueY = "all_rebates_new_buyers_90days_percentage";
        series7.dataFields.dateX = "date";
        series7.stacked = false;
        series7.columns.template.tooltipText = "{all_rebates_new_buyers_90days_percentage}%";
        series7.name = "New buyers 90 days %";
        series7.tooltipText = "{name}: {all_rebates_new_buyers_90days_percentage}%";
        series7.yAxis = valueAxis2;
        series7.fill = am4.core.color("#7cb342");
        series7.fillOpacity = 0.8;
        series7.strokeWidth = 0;

        let series8 = chart.series.push(new am4.charts.ColumnSeries());
        series8.columns.template.width = am4.core.percent(80);
        series8.dataFields.valueY = "all_rebates_old_buyers_90days_percentage";
        series8.dataFields.dateX = "date";
        series8.stacked = true;
        series8.columns.template.tooltipText = "{all_rebates_old_buyers_90days_percentage}%";
        series8.name = "Old buyers 90 days %";
        series8.tooltipText = "Old buyers 90 days: {all_rebates_old_buyers_90days_percentage}%";
        series8.yAxis = valueAxis2;
        series8.fill = am4.core.color("#999");
        series8.fillOpacity = 0.5;
        series8.strokeWidth = 0;
        series8.hidden = true;

        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "all_rebates";
        series1.dataFields.dateX = "date";
        series1.name = "All rebates";
        series1.stacked = false;
        series1.strokeWidth = 2;
        series1.stroke = am4.core.color("#1565c0");
        series1.tooltipText = "{all_rebates}";

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "all_rebates_new_buyers";
        series2.dataFields.dateX = "date";
        series2.name = "New buyers";
        series2.stacked = false;
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#ffc400");
        series2.tooltipText = "{all_rebates_new_buyers}";

        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "all_rebates_old_buyers";
        series3.dataFields.dateX = "date";
        series3.name = "Old buyers";
        series3.stacked = false;
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#d32f2f");
        series3.tooltipText = "{all_rebates_old_buyers}";

        let series4 = chart.series.push(new am4.charts.LineSeries());
        series4.dataFields.valueY = "all_rebates_new_buyers_90days";
        series4.dataFields.dateX = "date";
        series4.name = "New buyers 90days";
        series4.strokeWidth = 2;
        series4.stacked = false;
        series4.stroke = am4.core.color("#f50057");
        series4.tooltipText = "{all_rebates_new_buyers_90days}";

        let series9 = chart.series.push(new am4.charts.LineSeries());
        series9.dataFields.valueY = "all_rebates_old_buyers_90days";
        series9.dataFields.dateX = "date";
        series9.name = "Old buyers 90days";
        series9.strokeWidth = 2;
        series9.stacked = false;
        series9.stroke = am4.core.color("#ef6c00");
        series9.tooltipText = "{all_rebates_old_buyers_90days}";

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";
        series1.tooltip.background.fill = am4.core.color("#1565c0");

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#ffc400");

        series3.tooltip.background.cornerRadius = 3;
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 40;
        series3.tooltip.label.textAlign = "middle";
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#d32f2f");

        series4.tooltip.background.cornerRadius = 3;
        series4.tooltip.background.strokeOpacity = 0;
        series4.tooltip.pointerOrientation = "vertical";
        series4.tooltip.label.minWidth = 40;
        series4.tooltip.label.textAlign = "middle";
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4.core.color("#f50057");

        series9.tooltip.background.cornerRadius = 3;
        series9.tooltip.background.strokeOpacity = 0;
        series9.tooltip.pointerOrientation = "vertical";
        series9.tooltip.label.minWidth = 40;
        series9.tooltip.label.textAlign = "middle";
        series9.tooltip.getFillFromObject = false;
        series9.tooltip.background.fill = am4.core.color("#ef6c00");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series5);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series5;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-rebates-granularities-new-buyers').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawChromeChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-claims-by-chrome')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.min = 0;

        // Create series
        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "all_chrome_rebates";
        series1.dataFields.dateX = "date";
        series1.name = "All rebates";
        series1.strokeWidth = 2;
        series1.tooltipText = "{all_chrome_rebates}";

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series1;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-chrome-rebates-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawAffiliatesChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-affiliates')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());

        // Create series
        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "hits";
        series1.dataFields.dateX = "date";
        series1.name = "Hits";
        series1.strokeWidth = 2;
        series1.stroke = am4.core.color("#e74c3c");
        series1.tooltipText = "{valueY.formatNumber('#,###')} hits";

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "signups";
        series2.dataFields.dateX = "date";
        series2.name = "Signups";
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#26b99a");
        series2.tooltipText = "{valueY.formatNumber('#,###')} signups";

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";
        series1.tooltip.background.fill = am4.core.color("#e74c3c");

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#26b99a");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series1;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-affiliates-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawCouponsChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-coupons')[0], am4.charts.XYChart);
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.min = 0;

        // Create series
        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.name = "Clicks";
        series1.dataFields.valueY = "click";
        series1.dataFields.dateX = "date";
        series1.strokeWidth = 2;
        series1.stroke = am4.core.color("#c0392b");
        series1.tooltipText = "{valueY.formatNumber('#,###')} clicks";
        series1.minBulletDistance = 10;

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "hit";
        series2.dataFields.dateX = "date";
        series2.stroke = am4.core.color("#2ecc71");
        series2.strokeWidth = 2;
        series2.name = "Hits";
        series2.tooltipText = "{valueY.formatNumber('#,###')} hits";
        series2.minBulletDistance = 10;

        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "view";
        series3.dataFields.dateX = "date";
        series3.name = "Views";
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#f39c12");
        series3.tooltipText = "{valueY.formatNumber('#,###')} views";
        series3.minBulletDistance = 10;

        //Drop-shaped tooltips
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.label.minHeight = 15;
        series1.tooltip.background.cornerRadius = 15;
        series1.tooltip.background.fillOpacity = 0.9;
        series1.tooltip.label.minWidth = 33;
        series1.tooltip.label.textAlign = "middle";
        series1.tooltip.label.padding(10, 10, 10, 10);
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4.core.color("#c0392b");

        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.label.minHeight = 15;
        series2.tooltip.background.cornerRadius = 15;
        series2.tooltip.background.fillOpacity = 0.9;
        series2.tooltip.label.minWidth = 33;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.label.padding(10, 10, 10, 10);
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#2ecc71");

        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.label.minHeight = 15;
        series3.tooltip.background.cornerRadius = 15;
        series3.tooltip.background.fillOpacity = 0.9;
        series3.tooltip.label.minWidth = 33;
        series3.tooltip.label.textAlign = "middle";
        series3.tooltip.label.padding(10, 10, 10, 10);
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#f39c12");

        //Make bullets grow on hover
        let bullet1 = series1.bullets.push(new am4.charts.CircleBullet());
        bullet1.circle.strokeWidth = 2;
        bullet1.circle.radius = 4;
        bullet1.circle.fill = am4.core.color("#fff");

        let bullet2 = series2.bullets.push(new am4.charts.CircleBullet());
        bullet2.circle.strokeWidth = 2;
        bullet2.circle.radius = 4;
        bullet2.circle.fill = am4.core.color("#fff");

        let bullet3 = series3.bullets.push(new am4.charts.CircleBullet());
        bullet3.circle.strokeWidth = 2;
        bullet3.circle.radius = 4;
        bullet3.circle.fill = am4.core.color("#fff");

        let bullethover1 = bullet1.states.create("hover");
        bullethover1.properties.scale = 1.3;

        let bullethover2 = bullet2.states.create("hover");
        bullethover2.properties.scale = 1.3;

        let bullethover3 = bullet3.states.create("hover");
        bullethover3.properties.scale = 1.3;

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series2;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-coupons-granularities').on('click', '.btn',
            function (event) {
                event.preventDefault();
                const $button = $(this);
                $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
                $.get($button.attr('href')).then(function (response) {
                    chart.data = response.data;
                    $button.addClass('active').siblings().removeClass('active');
                }).always(function () {
                    $button.find('.fal').remove();
                });
            }).find('.btn:first').trigger('click');
    }

    static toggleAxes(ev) {
        let axis = ev.target.yAxis;
        let disabled = true;
        axis.series.each(function (series) {
            if (!series.isHiding && !series.isHidden) {
                disabled = false;
            }
        });
        axis.disabled = disabled;
    }

    //@todo pending review of this ticket in gitlab for inconsistencies with the payment chart and negative profit values
    //@todo https://gitlab.webmerica.com/rebatekey/website/commit/e96d233e7a43ac1673531f2f64caceffcd3b2e1d
    static async drawPaymentsChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-payments')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.numberFormatter = new am4.core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = '$#,##0.00';

        let valueAxisRight = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxisRight.numberFormatter = new am4.core.NumberFormatter();
        valueAxisRight.numberFormatter.numberFormat = '#,##0.';
        valueAxisRight.renderer.opposite = true;

        // Create series
        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "total_payments";
        series1.dataFields.dateX = "date";
        series1.name = "Amount";
        series1.strokeWidth = 2;
        series1.tooltipText = "${valueY}";

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "nb_payments";
        series2.dataFields.dateX = "date";
        series2.name = "# of payments";
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#26b99a");
        series2.tooltipText = "{valueY}";
        series2.yAxis = valueAxisRight;
        series2.events.on("hidden", this.toggleAxes);
        series2.events.on("shown", this.toggleAxes);

        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "inflow";
        series3.dataFields.dateX = "date";
        series3.name = "Inflow";
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#e74c3c");
        series3.tooltipText = "${valueY}";

        let series4 = chart.series.push(new am4.charts.LineSeries());
        series4.dataFields.valueY = "outflow";
        series4.dataFields.dateX = "date";
        series4.name = "Outflow";
        series4.strokeWidth = 2;
        series4.stroke = am4.core.color("#f39c12");
        series4.tooltipText = "${valueY}";

        let series5 = chart.series.push(new am4.charts.LineSeries());
        series5.dataFields.valueY = "profit";
        series5.dataFields.dateX = "date";
        series5.name = "Profit";
        series5.strokeWidth = 2;
        series5.stroke = am4.core.color("#9b59b6");
        series5.tooltipText = "${valueY}";

        let series6 = chart.series.push(new am4.charts.LineSeries());
        series6.dataFields.valueY = "lob_fees";
        series6.dataFields.dateX = "date";
        series6.name = "LOB fees";
        series6.strokeWidth = 2;
        series6.stroke = am4.core.color("#3498db");
        series6.tooltipText = "${valueY}";

        let series7 = chart.series.push(new am4.charts.LineSeries());
        series7.dataFields.valueY = "paypal_fees";
        series7.dataFields.dateX = "date";
        series7.name = "PayPal fees";
        series7.strokeWidth = 2;
        series7.stroke = am4.core.color("#7f8c8d");
        series7.tooltipText = "${valueY}";

        let series8 = chart.series.push(new am4.charts.LineSeries());
        series8.dataFields.valueY = "stripe_fees";
        series8.dataFields.dateX = "date";
        series8.name = "Stripe fees";
        series8.strokeWidth = 2;
        series8.stroke = am4.core.color("#2ecc71");
        series8.tooltipText = "${valueY}";

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#26b99a");

        series3.tooltip.background.cornerRadius = 3;
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 40;
        series3.tooltip.label.textAlign = "middle";
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#e74c3c");

        series4.tooltip.background.cornerRadius = 3;
        series4.tooltip.background.strokeOpacity = 0;
        series4.tooltip.pointerOrientation = "vertical";
        series4.tooltip.label.minWidth = 40;
        series4.tooltip.label.textAlign = "middle";
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4.core.color("#f39c12");

        series5.tooltip.background.cornerRadius = 3;
        series5.tooltip.background.strokeOpacity = 0;
        series5.tooltip.pointerOrientation = "vertical";
        series5.tooltip.label.minWidth = 40;
        series5.tooltip.label.textAlign = "middle";
        series5.tooltip.getFillFromObject = false;
        series5.tooltip.background.fill = am4.core.color("#9b59b6");

        series6.tooltip.background.cornerRadius = 3;
        series6.tooltip.background.strokeOpacity = 0;
        series6.tooltip.pointerOrientation = "vertical";
        series6.tooltip.label.minWidth = 40;
        series6.tooltip.label.textAlign = "middle";
        series6.tooltip.getFillFromObject = false;
        series6.tooltip.background.fill = am4.core.color("#3498db");

        series7.tooltip.background.cornerRadius = 3;
        series7.tooltip.background.strokeOpacity = 0;
        series7.tooltip.pointerOrientation = "vertical";
        series7.tooltip.label.minWidth = 40;
        series7.tooltip.label.textAlign = "middle";
        series7.tooltip.getFillFromObject = false;
        series7.tooltip.background.fill = am4.core.color("#7f8c8d");

        series8.tooltip.background.cornerRadius = 3;
        series8.tooltip.background.strokeOpacity = 0;
        series8.tooltip.pointerOrientation = "vertical";
        series8.tooltip.label.minWidth = 40;
        series8.tooltip.label.textAlign = "middle";
        series8.tooltip.getFillFromObject = false;
        series8.tooltip.background.fill = am4.core.color("#2ecc71");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series1;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-payments-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawPayoutsChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-payouts')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.numberFormatter = new am4.core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = '$#,##0.00';
        valueAxis.min = 0;

        let valueAxisRight = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxisRight.numberFormatter = new am4.core.NumberFormatter();
        valueAxisRight.numberFormatter.numberFormat = '#,##0.';
        valueAxisRight.renderer.opposite = true;
        valueAxisRight.renderer.grid.template.disabled = true;
        valueAxisRight.min = 0;

        // Create series
        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "total_payouts";
        series3.dataFields.dateX = "date";
        series3.name = "Both";
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#9b59b6");
        series3.tooltipText = "${total_payouts}";

        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "total_checks";
        series1.dataFields.dateX = "date";
        series1.name = "By check";
        series1.strokeWidth = 2;
        series1.tooltipText = "${total_checks}";

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "total_paypal";
        series2.dataFields.dateX = "date";
        series2.name = "By PayPal";
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#26b99a");
        series2.tooltipText = "${total_paypal}";

        let series4 = chart.series.push(new am4.charts.LineSeries());
        series4.dataFields.valueY = "nb_payouts";
        series4.dataFields.dateX = "date";
        series4.name = "# of both";
        series4.strokeWidth = 2;
        series4.stroke = am4.core.color("#f39c12");
        series4.tooltipText = "{nb_payouts}";
        series4.yAxis = valueAxisRight;

        let series5 = chart.series.push(new am4.charts.LineSeries());
        series5.dataFields.valueY = "nb_checks";
        series5.dataFields.dateX = "date";
        series5.name = "# of checks";
        series5.strokeWidth = 2;
        series5.stroke = am4.core.color("#e74c3c");
        series5.tooltipText = "{nb_checks}";
        series5.yAxis = valueAxisRight;

        let series6 = chart.series.push(new am4.charts.LineSeries());
        series6.dataFields.valueY = "nb_paypal";
        series6.dataFields.dateX = "date";
        series6.name = "# of PayPal";
        series6.strokeWidth = 2;
        series6.stroke = am4.core.color("#34495e");
        series6.tooltipText = "{nb_paypal}";
        series6.yAxis = valueAxisRight;

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#26b99a");

        series3.tooltip.background.cornerRadius = 3;
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 40;
        series3.tooltip.label.textAlign = "middle";
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#9b59b6");

        series4.tooltip.background.cornerRadius = 3;
        series4.tooltip.background.strokeOpacity = 0;
        series4.tooltip.pointerOrientation = "vertical";
        series4.tooltip.label.minWidth = 40;
        series4.tooltip.label.textAlign = "middle";
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4.core.color("#f39c12");

        series5.tooltip.background.cornerRadius = 3;
        series5.tooltip.background.strokeOpacity = 0;
        series5.tooltip.pointerOrientation = "vertical";
        series5.tooltip.label.minWidth = 40;
        series5.tooltip.label.textAlign = "middle";
        series5.tooltip.getFillFromObject = false;
        series5.tooltip.background.fill = am4.core.color("#e74c3c");

        series6.tooltip.background.cornerRadius = 3;
        series6.tooltip.background.strokeOpacity = 0;
        series6.tooltip.pointerOrientation = "vertical";
        series6.tooltip.label.minWidth = 40;
        series6.tooltip.label.textAlign = "middle";
        series6.tooltip.getFillFromObject = false;
        series6.tooltip.background.fill = am4.core.color("#34495e");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series1;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-payouts-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static async drawCampaignsChart() {
        const am4 = await LibraryLoader.loadAmCharts();

        am4.core.options.commercialLicense = true;
        let chart = am4.core.create($('#chart-campaigns')[0], am4.charts.XYChart);

        // Create value axis
        let dateAxis = chart.xAxes.push(new am4.charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4.charts.ValueAxis());
        valueAxis.min = 0;

        // Create series
        let series1 = chart.series.push(new am4.charts.LineSeries());
        series1.dataFields.valueY = "hits";
        series1.dataFields.dateX = "date";
        series1.name = "Hits";
        series1.strokeWidth = 2;
        series1.stroke = am4.core.color("#2ecc71");
        series1.tooltipText = "{hits}";

        let series2 = chart.series.push(new am4.charts.LineSeries());
        series2.dataFields.valueY = "unique_hits";
        series2.dataFields.dateX = "date";
        series2.name = "Unique hits";
        series2.strokeWidth = 2;
        series2.stroke = am4.core.color("#f39c12");
        series2.tooltipText = "{unique_hits}";

        let series3 = chart.series.push(new am4.charts.LineSeries());
        series3.dataFields.valueY = "views";
        series3.dataFields.dateX = "date";
        series3.name = "Views";
        series3.strokeWidth = 2;
        series3.stroke = am4.core.color("#3498db");
        series3.tooltipText = "{views}";

        let series4 = chart.series.push(new am4.charts.LineSeries());
        series4.dataFields.valueY = "unique_views";
        series4.dataFields.dateX = "date";
        series4.name = "Unique views";
        series4.strokeWidth = 2;
        series4.stroke = am4.core.color("#d35400");
        series4.tooltipText = "{unique_views}";

        let series5 = chart.series.push(new am4.charts.LineSeries());
        series5.dataFields.valueY = "instructions";
        series5.dataFields.dateX = "date";
        series5.name = "Instructions";
        series5.strokeWidth = 2;
        series5.stroke = am4.core.color("#9b59b6");
        series5.tooltipText = "{instructions}";

        let series6 = chart.series.push(new am4.charts.LineSeries());
        series6.dataFields.valueY = "unique_instructions";
        series6.dataFields.dateX = "date";
        series6.name = "Unique instructions";
        series6.strokeWidth = 2;
        series6.stroke = am4.core.color("#7f8c8d");
        series6.tooltipText = "{unique_instructions}";

        let series7 = chart.series.push(new am4.charts.LineSeries());
        series7.dataFields.valueY = "sales";
        series7.dataFields.dateX = "date";
        series7.name = "Sales";
        series7.strokeWidth = 2;
        series7.stroke = am4.core.color("#34495e");
        series7.tooltipText = "{sales}";

        let series8 = chart.series.push(new am4.charts.LineSeries());
        series8.dataFields.valueY = "unique_sales";
        series8.dataFields.dateX = "date";
        series8.name = "Unique sales";
        series8.strokeWidth = 2;
        series8.stroke = am4.core.color("#c0392b");
        series8.tooltipText = "{unique_sales}";

        // Create tooltips
        series1.tooltip.background.cornerRadius = 3;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "vertical";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.textAlign = "middle";
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4.core.color("#2ecc71");

        series2.tooltip.background.cornerRadius = 3;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4.core.color("#f39c12");

        series3.tooltip.background.cornerRadius = 3;
        series3.tooltip.background.strokeOpacity = 0;
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.label.minWidth = 40;
        series3.tooltip.label.textAlign = "middle";
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4.core.color("#3498db");

        series4.tooltip.background.cornerRadius = 3;
        series4.tooltip.background.strokeOpacity = 0;
        series4.tooltip.pointerOrientation = "vertical";
        series4.tooltip.label.minWidth = 40;
        series4.tooltip.label.textAlign = "middle";
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4.core.color("#d35400");

        series5.tooltip.background.cornerRadius = 3;
        series5.tooltip.background.strokeOpacity = 0;
        series5.tooltip.pointerOrientation = "vertical";
        series5.tooltip.label.minWidth = 40;
        series5.tooltip.label.textAlign = "middle";
        series5.tooltip.getFillFromObject = false;
        series5.tooltip.background.fill = am4.core.color("#9b59b6");

        series6.tooltip.background.cornerRadius = 3;
        series6.tooltip.background.strokeOpacity = 0;
        series6.tooltip.pointerOrientation = "vertical";
        series6.tooltip.label.minWidth = 40;
        series6.tooltip.label.textAlign = "middle";
        series6.tooltip.getFillFromObject = false;
        series6.tooltip.background.fill = am4.core.color("#7f8c8d");

        series7.tooltip.background.cornerRadius = 3;
        series7.tooltip.background.strokeOpacity = 0;
        series7.tooltip.pointerOrientation = "vertical";
        series7.tooltip.label.minWidth = 40;
        series7.tooltip.label.textAlign = "middle";
        series7.tooltip.getFillFromObject = false;
        series7.tooltip.background.fill = am4.core.color("#34495e");

        series8.tooltip.background.cornerRadius = 3;
        series8.tooltip.background.strokeOpacity = 0;
        series8.tooltip.pointerOrientation = "vertical";
        series8.tooltip.label.minWidth = 40;
        series8.tooltip.label.textAlign = "middle";
        series8.tooltip.getFillFromObject = false;
        series8.tooltip.background.fill = am4.core.color("#c0392b");

        // Add scrollbar
        chart.scrollbarX = new am4.charts.XYChartScrollbar();
        (<any>chart.scrollbarX).series.push(series1);
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        chart.cursor = new am4.charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series1;

        // Add legend
        chart.legend = new am4.charts.Legend();

        $('#btn-campaigns-granularities').on('click', '.btn', function (event) {
            event.preventDefault();
            const $button = $(this);
            $button.append(' <i class="fal fa-circle-notch fa-spin"></i>');
            $.get($button.attr('href')).then(function (response) {
                chart.data = response.data;
                $button.addClass('active').siblings().removeClass('active');
            }).always(function () {
                $button.find('.fal').remove();
            });
        }).find('.btn:first').trigger('click');
    }

    static loadPreviewImage($container, removeBtn = false) {
        const $input = $container.find('[type=file]');
        const fileName = $input.val();
        const dotFile = fileName.lastIndexOf(".");
        const extFile = fileName.substr(dotFile, fileName.length).toLowerCase().replace(".", "");
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        if ($.inArray(extFile, allowedExtensions) > -1) {
            if ($input.get(0).files.length > 0) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    $container.fadeOut('fast', function () {
                        //Hide the current image/icon.
                        $container.find('.text-center *').hide();
                    }).fadeIn('fast', function () {
                        $('<img src="' + String(e.target.result) + '" ' +
                            'class="preview-image img-fluid" alt="logo" style="max-height: 250px;">')
                            .prependTo($container.find('.text-center'));
                        if (removeBtn) {
                            $('<button type="button" class="btn btn-danger btn-sm btn-remove px-2">' +
                                '<i class="fal fa-times"></i>' +
                            '</button>').css({
                                position: 'absolute',
                                top: 0,
                                right: 0
                            }).insertAfter('.preview-image');
                            $container.find('.btn-remove').on('click', function () {
                                Admin.removePreviewImage($container);
                            });
                        }
                    });
                }

                reader.readAsDataURL($input.get(0).files[0]);
            }
            return true;
        }

        Notification.error("This file cannot be used as a store logo, please select another type file.");
        return false;
    }

    static removePreviewImage($container, hidden = false) {
        //Remove the preview.
        $container.find('.text-center .preview-image').remove();
        //Remove the delete button.
        $container.find('.btn-remove').remove();
        //Reset input.
        $container.find('[type=file]').val('').trigger('change');
        if (hidden) {
            $container.append('<input type="hidden" name="remove_logo" value="1">');
            Notification.info("The changes will be permanent after pressing the save button.");
        } else {
            $container.find('[name=remove_logo]').remove();
        }
        $container.find('.text-center .fal').show();
    }
}