import bsCustomFileInput from 'bs-custom-file-input';

toastr.options.closeButton = true;
toastr.options.progressBar = true;
toastr.options.positionClass = 'toast-top-center';

Dropzone.autoDiscover = false;

$(function () {

    // Tooltips
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body'
    });

    // Popovers
    $('[data-toggle="popover"]').popover({
        container: 'body'
    });

    // Make autofocus attribute works in modals
    $(document).on('shown.bs.modal', function (e) {
        $('[autofocus]', e.target).trigger('focus');
    });

    //Select 2 auto open on focus
    $(document).on('focus', '.select2:enabled', function (e) {
        if (e.originalEvent) {
            $(this).siblings('select').select2('open');
        }
    });

    // Smooth scroll
    new SmoothScroll('.smooth-scroll', {
        offset: 81
    });

    //Animate On Scroll
    AOS.init();

    //File browsers
    bsCustomFileInput.init();

    RK.Project.init();

});