import {Modal} from "./core";
import {Favorite} from "./favorite";

export class CouponListing {

    static init() {
        $(document).on('click', '.listing-card .listing-coupon', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $listing = $(this);
            Modal.open({
                href: '/buyer/modal/coupon/view?id=' + $listing.data('id'),
                size: 'modal-lg',
                class: 'modal-campaign'
            });
        }).on('click', '.listing-card .listing-coupon .share, .share .collapse', function (event) {
            event.stopPropagation();
        }).on('click', '.listing-card .listing-coupon .favorite', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $icon = $(this).find('.fa-heart');
            const $listing = $icon.parents('.listing-container');
            if ($icon.hasClass('fas')) {
                Favorite.remove($listing).then(function () {
                    $('[id="' + $listing.attr('id') + '"]')
                        .find('.favorite .fa-heart')
                        .removeClass('fas').addClass('fal');
                });
            } else {
                Favorite.add($listing).then(function () {
                    $('[id="' + $listing.attr('id') + '"]')
                        .find('.favorite .fa-heart')
                        .removeClass('fal').addClass('fas');
                });
            }

        }).on('click', '.btn-working', function (event) {
            const $button = $(event.target);
            const $listing = $button.parents('.listing-single');
            $.ajax({
                url: '/buyer/ajax/coupon/working',
                method: 'POST',
                data: {
                    id: $listing.data('id'),
                   working: 1
                }
            }).then(function () {
                $button.siblings().find('i').removeClass('fas').addClass('fal');
                $button.find('i').removeClass('fal').addClass('fas');
            });
        }).on('click', '.btn-not-working', function (event) {
            const $button = $(event.target);
            const $listing = $button.parents('.listing-single');
            $.ajax({
                url: '/buyer/ajax/coupon/working',
                method: 'POST',
                data: {
                    id: $listing.data('id'),
                    working: 0
                }
            }).then(function () {
                $button.siblings().find('i').removeClass('fas').addClass('fal');
                $button.find('i').removeClass('fal').addClass('fas');
            });
        });

        // @ts-ignore
        new Clipboard('.btn-clipboard-listing-coupon', {container: $('.modal')[0]}).on('success', function (event) {
            const $button = $(event.trigger);
            $button.html('<i class="fal fa-spin fa-spinner"></i> Redirecting...');
            const $listing = $button.parents('.listing-single');
            $.ajax({
                url: '/buyer/ajax/coupon/click',
                method: 'POST',
                data: {
                    id: $listing.data('id')
                }
            }).then(function () {
                $button.siblings('.sold-by')
                    .replaceWith(`
                        <div class="d-md-flex align-items-center justify-content-center">
                            <button data-e2e="coupon-did-work" class="btn btn-link btn-working btn-sm text-success">
                                <i class="fal fa-thumbs-up"></i> The coupon did work
                            </button>
                            <button data-e2e="coupon-didnt-work" class="btn btn-link btn-not-working btn-sm text-danger">
                                <i class="fal fa-thumbs-down"></i> The coupon didn't work
                            </button>
                        </div>`);
                setTimeout(function () {
                    window.open($listing.data('url'), '_blank');
                }, 1000);
            }).always(function () {
                setTimeout(function () {
                    $button.html('Copy & Go to Marketplace');
                }, 1000);
            });
        });
    }

    static sortPictures(id, items) {
        return $.post('/seller/coupons/coupon/ajax/sort-pictures', {
            id: id,
            items: items
        });
    }

    static removePicture(id) {
        return $.post('/seller/coupons/coupon/ajax/delete-picture', {
            id: id
        });
    }

    static rotatePicture(id) {
        return $.post('/seller/coupons/coupon/ajax/rotate-picture', {
            id: id
        });
    }
}