import * as $ from "jquery";

export class Seller {

    static async drawDashboardChart($element: JQuery, data) {
        const ChartJS = await import(/* webpackChunkName: "chart.js" */ "chart.js");
        new ChartJS.Chart((<any>$element[0]).getContext('2d'), {
            type: 'line',
            data: data,
            responsive: false,
            maintainAspectRatio: false,
            options: {
                tooltips: {
                    mode: 'index'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    static async drawSummaryRebatesChart($element: JQuery, data) {
        const ChartJS = await import(/* webpackChunkName: "chart.js" */ "chart.js");
        new ChartJS.Chart((<any>$element[0]).getContext('2d'), {
            type: 'line',
            data: data,
            options: {
                tooltips: {
                    mode: 'index'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    static async drawSummaryEventChart($element: JQuery, data) {
        const ChartJS = await import(/* webpackChunkName: "chart.js" */ "chart.js");
        new ChartJS.Chart((<any>$element[0]).getContext('2d'), {
            type: 'line',
            data: data,
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }
}