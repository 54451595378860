export class Message {

    static init() {
        $(document).on('click', '.mark-unread',  (event) => {
            event.preventDefault();
            const $link = $(event.target).parent();
            const $message = $link.parents('.message');
            $.get($link.attr('href')).then(() => {
                $link.fadeOut(() => {
                    $link.remove();
                    $message.addClass('new')
                        .find('.position-relative').removeClass('mb-3');
                });
            });
        });
    }
}
