import {Alert, Modal, Notification, WebPush} from "./core";
import {RebateListing} from "./rebate-listing";
import {CouponListing} from "./coupon-listing";
import {DealListing} from "./deal-listing";
import {Listing} from "./listing";
import {Message} from "./message";

declare var lozad: any;

export class Project {

    static lazyLoader = null;

    static initLazyLoader() {
        if (!this.lazyLoader) {
            this.lazyLoader = lozad();
        }
        this.lazyLoader.observe();
        $('[data-toggle=tooltip]').tooltip();
    }

    static init() {
        this.initLazyLoader();
        WebPush.init();
        Modal.init();
        Alert.init();
        Listing.init();
        RebateListing.init();
        CouponListing.init();
        DealListing.init();
        Message.init();

        $(document).ajaxError((event, xhr) => {
            //Displays notification.
            if (xhr.status === 503) {
                Notification.error(xhr.responseJSON.message);
                if (typeof xhr.responseJSON.redirect !== 'undefined') {
                    setTimeout(function () {
                        window.location.href = xhr.responseJSON.redirect;
                    }, 5000);
                }
            } else if (xhr.status === 401) {
                const options = {
                    href: '/modal/administrator/auth',
                    size: 'modal-lg'
                };

                if ($('body').hasClass('modal-open')) {
                    Modal.reload(options);
                } else {
                    Modal.open(options);
                }
            }
        });
    }

    /**
     *
     * @param $container
     */
    static loader($container: JQuery): JQuery {
        const $loader = $(
            `<div id="spin-loader" class="box d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>`);
        $container.append($loader);

        return $loader;
    }
}
