export class ListingCategory {

    static sortImages(id, items) {
        return $.post('/category/ajax/image/sort', {
            id: id,
            items: items
        });
    }

    static removeImage(id) {
        return $.post('/category/ajax/image/delete', {
            id: id
        });
    }
}