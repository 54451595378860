import * as $ from "jquery";

interface ModalOptions {
    target?: string;
    href?: string;
    size?: string;
    class?: string;
    backdrop?: string;
}

export class Modal {

    static load(url: string) {
        return $.ajax({url: url, global: false});
    }

    static loadContent($modal, url) {
        return Modal.load(url).then((response) => {
            $modal.data('initial-content', $modal.find('.modal-content').html());
            $modal.find('.modal-content').find('.loading').fadeOut(() => {
                $modal.find('.modal-content').html(response);
                $modal.addClass('loaded').trigger($.Event('loaded.bs.modal'));
            });
        });
    }

    static setOptions($target, options: ModalOptions) {
        if (options.href) {
            $target.attr('href', options.href);
        }
        if (options.size) {
            $target.data('modal-size', options.size);
        }
        if (options.class) {
            $target.data('class', options.class);
        }
        if (options.backdrop) {
            $target.data('backdrop', options.backdrop);
        }
    }

    static applyOptions($modal, $options) {
        if ($options.data('class')) {
            $modal.addClass($options.data('class')).data('class', $options.data('class'));
        }
        if ($options.data('modal-size')) {
            $modal.data('modal-size', $options.data('modal-size'))
                .find('.modal-dialog').addClass($options.data('modal-size'));
        }
        if ($options.data('modal-border')) {
            $modal.data('modal-border', $options.data('modal-border'))
                .find('.modal-content').addClass($options.data('modal-border'));
        }
        if ($options.attr('href')) {
            this.loadContent($modal, $options.attr('href')).fail((xhr: JQueryXHR) => {
                let url = '/error/modal/whoops';
                let modalSize = 'modal-xl';
                if (xhr.status === 401) {
                    url = '/modal/administrator/auth';
                    modalSize = 'modal-md';
                } else if (xhr.status === 503) {
                    url = '/error/modal/upgrading';
                }
                $modal.data('href', $options.attr('href'));
                $modal.data('class') && $modal.removeClass($modal.data('class'));
                $modal.data('modal-size') && $modal.find('.modal-dialog').removeClass($modal.data('modal-size'));
                $modal.find('.modal-dialog').addClass(modalSize);
                this.loadContent($modal, url).fail(() => {
                    console.error("Impossible to load the modal from URL " + url);
                });
            });
        }
    }

    static reset($modal) {
        $modal.removeData('bs.modal');
        if ($modal.data('class')) {
            $modal.removeClass($modal.data('class'));
        }
        if ($modal.data('modal-size')) {
            $modal.find('.modal-dialog').removeClass($modal.data('modal-size'));
        }
        if ($modal.data('modal-border')) {
            $modal.find('.modal-content').removeClass($modal.data('modal-border'));
        }
        if ($modal.hasClass('loaded')) {
            $modal.find('.modal-content').html($modal.data('initial-content'));
            $modal.removeClass('loaded').removeAttr('href').removeAttr('style');
        }
        $('.modal-backdrop.show').remove();
        $modal.modal('dispose');
    }

    static reload(options: ModalOptions) {
        const $modal: JQuery = (options.target) ? $(options.target) : $('#generic-modal');
        this.reset($modal);
        this.setOptions($modal, options);
        $modal.modal('show');
    }

    static open(options: ModalOptions) {
        const $modal = (options.target) ? $(options.target) : $('#generic-modal');
        this.setOptions($modal, options);
        $modal.modal('show');

        return $modal;
    }

    static hide(options: any = {}) {
        const $modal: JQuery = (options.target) ? $(options.target) : $('#generic-modal');
        $modal.modal('hide');
    }

    static init() {
        //Modal opening.
        $(document).on('show.bs.modal', '.modal', (event: any) => {
            const $modal = $(event.target);
            let $options;
            if (event.relatedTarget) {
                $options = $(event.relatedTarget);
            } else {
                $options = $modal;
            }
            this.applyOptions($modal, $options);
        }).on('shown.bs.modal', '.modal', (event) => {
            $(event.target).find('[data-toggle=tooltip]').tooltip();
        }).on('hidden.bs.modal', '.modal', (event) => {
            const $modal = $(event.target);
            this.reset($modal);
        }).on("contextmenu", '[data-toggle=modal]', function (event) {
            event.preventDefault();
            $(this).trigger('click');
        }).on('mousedown', '[data-toggle=modal]', function (event) {
            // @ts-ignore
            if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
                if (event.button == 4) {
                    event.preventDefault();
                    $(this).trigger('click');
                }
            } else {
                if (event.button == 1) {
                    event.preventDefault();
                    $(this).trigger('click');
                }
            }
        });
    }
}