export class Listing {

    static init()
    {
        $(document).on('loaded.bs.modal', '.modal', (event) => {
            const $slider = $(event.target).find('.slider-main-img');
            this.loadSlider($slider);
        })
    }

    static loadSlider($slider) {
        const interval = setInterval(() => {
            if ($slider.is(':visible')) {
                this.slider($slider);
                clearInterval(interval);
            }
        }, 250);
    }

    static slider($slider: JQuery) {
        const optionsSlider = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: '.slider-nav',
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        dots: true,
                    }
                },
            ],
        };

        const $sliderNav = $slider.siblings('.slider-nav');
        const optionsSliderNav = {
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.slider-main-img',
            dots: false,
            vertical: true,
            focusOnSelect: true,
            initialSlide: 0,
            prevArrow: '<div class="arrow-up"><i class="fas fa-chevron-up text-center"></i></div>',
            nextArrow: '<div class="arrow-down"><i class="fas fa-chevron-down text-center"></i></div>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    }
                },
            ],
        };

        $slider.on('init', function () {
            $('.listing-slider .loading').remove();
        });
        (<any>$slider).slick(optionsSlider);
        (<any>$sliderNav).slick(optionsSliderNav);

        $slider.css('visibility', 'visible');
        $sliderNav.css('visibility', 'visible');
    }
}