import {Modal} from "./core";

export class DealListing {

    static init() {
        $(document).on('click', '.listing-card .listing-deal', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $listing = $(this);
            Modal.open({
                href: '/buyer/modal/deal/view?id=' + $listing.data('id'),
                size: 'modal-lg',
                class: 'modal-campaign'
            });
        }).on('click', '.listing-card .listing-deal .share, .share .collapse', function (event) {
            event.stopPropagation();
        });

        // @ts-ignore
        new Clipboard('.btn-clipboard-listing-deal', {container: $('.modal')[0]}).on('success', function (event) {
            const $button = $(event.trigger);
            $button.html('<i class="fal fa-spin fa-spinner"></i> Redirecting...');
            const $listing = $button.parents('.listing-single');
            setTimeout(function () {
                window.open($listing.data('url'), '_blank');
                $button.html('Copy & Go to Marketplace');
            }, 1000);
        });
    }
}