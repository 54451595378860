import * as $ from "jquery";

export class Category {
    static generateChildRow()
    {
        $(document).on('click', '.with-children',function (e) {
            e.preventDefault();
            const $btn = $(this);
            let id = $btn.attr('id');
            if ($btn.hasClass('fa-minus-circle')) {
                $btn.removeClass('fa-minus-circle').addClass('fa-plus-circle');
                $("#child-" + id).fadeOut('fast', function (){
                    $(this).remove();
                });
            } else {
                // Loading
                $("#tr-" + id).after(
                    '<td id="td-loading' + id + '" colspan="4">' +
                    '<div class="loading loading-generic text-center p-5">\n' +
                    '<i class="fal fa-spinner fa-spin fa-5x"></i>\n' +
                    '</div>' +
                    '</td>'
                );
                $btn.removeClass('fa-plus-circle').addClass('fa-minus-circle');
                // Get html related to children
                $.get('html/category/children', {id: id}).done(function (response) {
                    let $content = $(response).hide();
                    $('#tr-' + id).addClass('shown-' + id).after($content);
                    $("#td-loading" + id).fadeOut('fast', function (){
                       $(this).remove();
                       $content.fadeIn('slow');
                    });
                });
            }
        });
    }
}